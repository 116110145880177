.calendar-wrap {
  display: flex;
  flex-direction: column;
  width: 21.875rem;
  font-family: var(--font-secondary);

  :global(.react-calendar__navigation__label) {
    pointer-events: none;
    cursor: default;
  }

  .custom-calendar {
    background-color: transparent;
    width: 100%;
    color: darkblue;
    margin-bottom: 1.25rem;

    .custom-table {
      border-spacing: 6px;
      table-layout: fixed;
      border-collapse: separate;
      width: 100%;
    }

    .day-name {
      padding: 0.9375rem;
    }

    .date-day {
      background: #fff;
      border-radius: 0.3125rem;
      color: darkblue;
      text-align: center;
      text-box: auto;
      width: 100%;
      aspect-ratio: 1 / 1;
      text-align: center;
      vertical-align: middle;
      white-space: nowrap;
      // margin: 1.5px
      display: flex;
      align-items: center;
      justify-content: center;

      // p {
      //   padding: 0.9375rem;
      // }
    }
    .custom-today {
      background-color: #dbdff1;
      font-size: 1.75rem;
    }

    .other-month-day {
      opacity: 0.3;
      pointer-events: none;
    }

    .event-day {
      font-weight: bold;
      position: relative;
      cursor: pointer;
      box-shadow: 0px 0.625rem 0.9375rem -0.5rem rgba(0, 0, 0, 0.75);

      .day-info {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .event-indicators {
        position: absolute;
        padding: 0.0625rem 0.1875rem;
        border-radius: 0.125rem;
        display: flex;
        gap: 0.125rem;
        width: max-content;
        right: -0.3125rem;
        top: 0;

        .event-circle {
          position: relative;
          display: block;
          width: 0.875rem;
          height: 0.875rem;
          border-radius: 50%;
        }
      }

      .custom_tooltip {
        position: absolute;
        right: -1.5625rem;
        top: calc(100% + 0.1875rem);
        display: none;
        padding: 1.25rem;
        width: 21.875rem;
        background: rgba(54, 56, 60, 1);
        color: #fff;
        border-radius: 0.9375rem;
        box-shadow: 0px 0.1875rem 0.75rem -0.3125rem rgba(0, 0, 0, 0.75);
        z-index: 99;
        font-size: 0.875rem;
        font-weight: 400;

        p {
          padding: 0;
        }
      }
    }
    .show-events {
      .custom_tooltip {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.625rem;

        &__block {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          width: 100%;
          white-space: break-spaces;
          text-align: start;

          p {
            opacity: 0.6;
            font-weight: 350;
          }
        }
        &__blocks {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 0.625rem;
        }
        &__header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          font-size: 1rem;

          .event-name {
            display: flex;
            align-items: center;
            gap: 0.625rem;
            text-transform: uppercase;

            span {
              display: block;
              width: 0.875rem;
              height: 0.875rem;
              border-radius: 50%;
            }
          }

          .event-buttons {
            display: flex;
            align-items: center;
            gap: 0.3125rem;

            .active-btn {
              position: relative;
              font-size: 1rem;
              &::after {
                content: '';
                position: absolute;
                width: calc(100% + 0.25rem);
                height: 0.0625rem;
                bottom: 0.25rem;
                background: #fff;
                left: -0.125rem;
                right: -0.125rem;
              }
            }
          }
        }
      }
    }

    .highlight-date {
      background-color: rgba(219, 223, 241, 1) !important;
      color: darkblue !important;
      font-weight: bold;
      font-size: 1.125rem;
    }

    .evet-wrap {
      padding: 0.1875rem;
    }
  }

  .custom-weekday {
    padding: 0.9375rem;
    text-transform: uppercase;
    font-size: 0.875rem;
  }
  .hide-element {
    display: none !important;
  }

  .color-info-wrap {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    background: #fff;
    border-radius: 0.5rem;
    padding: 0.9375rem 1.25rem;
    gap: 0.625rem;

    .info-item {
      display: flex;
      align-items: center;
      gap: 1rem;

      span {
        width: 0.625rem;
        height: 0.625rem;
        border-radius: 50%;
      }
    }
  }
  .header-calendar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    // margin-bottom: 24px;

    .date-info {
      display: flex;
      align-items: end;
      gap: 0.3125rem;
      color: var(--color-dark-blue);
      text-align: center;
      font-size: 1.25rem;
      font-weight: 400;
      line-height: 1.6rem;
      text-transform: uppercase;
      padding: 0.9375rem;

      span {
        font-size: 2rem;
      }
    }
    .calendar-icons {
      display: flex;
      align-items: center;
      gap: 0.3125rem;
      .calendar-icon {
        padding: 0.9375rem;
        cursor: pointer;
      }
      .icon-modal {
        position: relative;

        .modal {
          position: absolute;
          // left: calc(100% - 0.625rem);
          left: max(-15.625rem, min(100vw - 21.875rem, -15.625rem));
          top: 100%;
          box-shadow: 0px 0.1875rem 0.75rem -0.3125rem rgba(0, 0, 0, 0.75);
          z-index: 1;
        }
      }
    }
  }
}
