.grid {
  display: flex;
  gap: 2rem;
  width: 100%;
  justify-content: space-between;
  flex-direction: column;

  &__cards {
    display: flex;
    gap: 2rem;
    width: 100%;
  }
}

.card {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  height: 100%;
  color: var(--color-dark-blue);
  padding: 56px 0;

  &__number {
    font-size: 69px;
    font-weight: 700;
  }

  &__C {
    font-size: 69px;
    font-weight: 400;
    line-height: 82.81px;
  }

  div {
    display: flex;
    gap: 6px;
    align-items: center;
    justify-self: center;
  }

  h1 {
    color: var(--color-dark-blue);
    font-size: 60px;
    align-self: center;
    justify-self: center;
    padding-top: 1rem;
    display: flex;
  }

  p {
    color: var(--color-dark-blue);
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    display: flex;
  }

  span {
    font-size: 18px;
  }

  &__text {
    @media (max-width: 1300px) {
      line-height: 16px;
    }
  }

  &__temp {
    font-size: 24px;
    font-weight: 600;
    line-height: 25px;
  }

  &__text-wrapper {
    display: flex;
    flex-direction: column;
    gap: 40px !important;
  }

  &__gradus {
    font-size: 24px !important;
    color: var(--color-dark-blue);
    font-weight: 800;
    height: 82.81px;
  }

  &__gradus2 {
    font-size: 12px !important;
    color: var(--color-dark-blue);
    font-weight: 800;
    height: 40px;
  }

  &__red {
    color: #ff3d3d;
    display: flex;
  }

  &__gradus3 {
    font-size: 12px !important;
    font-weight: 800;
  }
}

.inside {
  position: absolute;
  top: 30%;
  left: 20%;
}

.flex {
  display: flex;
  gap: 30px;
  align-items: flex-start;
}

.emission-types {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  list-style: none;
  background-color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 56px;
  height: fit-content;

  > li {
    border-radius: 46px;
    font-size: 1rem;
    font-weight: 500;
    line-height: normal;
    padding: 0.5em 1.5em;
    cursor: pointer;
    color: var(--color-dark-blue);
    flex: 1;

    &:hover {
      background: #c2d4f8;
    }
  }

  .checked {
    background: var(--color-blue);
    color: #fff;
    box-shadow: 0px 31px 25px 0px #2934951f;
  }
}

.emission-type {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.grid2 {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 2rem;
  width: 100%;
}

.cards-row {
  display: flex;
  gap: 2rem;
  width: 100%;
}
