.wrapper {
  display: flex;
  flex-wrap: wrap;
  padding-top: 0;
  width: 100%;
  gap: 2rem;
  & > div {
    flex: 1 100%;
  }
  @media (min-width: 768px) {
    flex-wrap: nowrap;
    & > div {
      &:first-child {
        flex: 1 75%;
      }
      &:last-child {
        flex: 1 25%;
      }
    }
  }
}

.map {
  position: relative;
  width: 100%;
  height: 100%;
  // background: #f4f4f4;
  border-radius: 8px;
  overflow: hidden;
}

.mapSvg {
  width: 100%;
  height: 100%;
  display: block;
}

.mapPoint {
  cursor: pointer;
  transition: r 0.2s ease-in-out;

  &:hover {
    r: 6;
  }
}

.mapContainer {
  width: 100%;
  height: 100%;
  min-height: 82vh;
  z-index: 1;
  background: #f4f4f4;

  :global {
    .leaflet-container {
      background: #f4f4f4;
    }

    .leaflet-control-container {
      display: none;
    }

    .leaflet-popup-content-wrapper {
      border-radius: 4px;
      background: white;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    .leaflet-popup-content {
      margin: 8px 12px;
      font-size: 14px;
    }

    .leaflet-popup-tip-container {
      display: none;
    }
  }
}

.cards {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  z-index: 9;
  height: 80vh;
  padding-right: 1rem;
  overflow-y: auto;
  overflow-x: hidden;
}

.card {
  &__header {
    display: grid;
    grid-template-columns: 15% 15% 35% 15%;
    padding: 1rem 2rem;
    align-items: center;
    position: relative;
    gap: 2rem;

    > div {
      display: flex;
      gap: 1rem;
      cursor: pointer;
    }

    > span {
      margin-left: 1rem;
    }
  }

  &__sources {
    max-height: 336px;
    overflow-y: auto;
    position: absolute;
    z-index: 977;
    top: 70%;
    left: 44%;
    background: rgba(31, 38, 47, 0.5);
    border-radius: 12px;
    color: #fff;
    display: flex;
    flex-direction: column;
    width: 50%;

    > span {
      cursor: pointer;
      padding: 1rem;
      &:hover {
        background: rgba(69, 91, 220, 0.5);
      }
    }
  }

  &__flex {
    display: flex;
    flex-direction: column;
  }

  &__body {
    padding: 1rem 2rem;

    > div {
      display: grid;
      grid-template-columns: 40% 60%;
      gap: 1rem;
      padding: 1rem;

      > p {
        align-self: center;
        color: var(--color-dark-blue);
        font-size: 40px;
      }
    }

    &__wrap {
      display: flex;
      gap: 6px;
    }
    &__more {
      padding-top: 1rem;
      text-align: end;
      font-size: 16px;
      cursor: pointer;
      color: var(--color-dark-blue);
      text-decoration: underline;
    }

    &__info--normal {
      color: #455bdc;
    }

    &__info--high {
      color: #ff3d3d !important;
    }

    &__info2 {
      color: #6496fb;
      font-size: 16px;
    }
  }
}

.line {
  width: 100%;
  height: 1px;
  background-color: rgba(69, 91, 220, 0.5);
  display: block;
}

.cross {
  position: relative;
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.cross::before,
.cross::after {
  content: "";
  position: absolute;
  height: 2px;
  width: 100%;
  background-color: #fff;
  top: 50%;
  left: 0;
  transform-origin: center;
}

.cross::before {
  transform: rotate(45deg);
}

.cross::after {
  transform: rotate(-45deg);
}
.arrow {
  transform: rotate(90deg);
  transition: transform 0.5s ease;
}

.arrow2 {
  transition: transform 0.5s ease;
}

.icon {
  position: absolute;
  cursor: pointer;
  z-index: 99;
}

.circle {
  position: absolute;
  width: 150px;
  height: 150px;
  border-radius: 50%;

  &--LOW {
    background-color: rgba(69, 91, 220, 0.1);
  }

  &--NORMAL {
    background-color: rgba(254, 185, 0, 0.1);
  }

  &--HIGH {
    background-color: rgba(227, 28, 0, 0.2);
  }
}

.org-point {
  position: relative;
  &__division {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  position: absolute;
  color: #fff;
  top: -10;
  display: flex;
  gap: 13px;
  cursor: pointer;
  // background-image: url('./../../../assets/icons/wrapper.svg');
  align-items: flex-start;
  padding: 0.5rem;
  background: #36383c;
  border-radius: 15px;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  background-repeat: no-repeat;
  background-size: contain;
  min-width: 100px;
  z-index: 10;
  font-family: var(--font-secondary);

  &:hover {
    z-index: 11;
  }

  img {
    z-index: 10;
    padding: 0.25rem;
    border-radius: 12px;
    min-width: 30px;
    min-height: 30px;
  }

  p {
    z-index: 10;
    font-weight: 700;
    font-size: 0.8125rem;
  }

  &__infobox {
    position: absolute;
    background: #36383c;
    width: 320px;
    max-width: 320px;
    box-shadow:
      0px 9px 7px 0px #29349516,
      0px 4px 3px 0px #2934950f;
    display: flex;
    gap: 0.625rem;
    color: #fff;
    top: 47.45px;
    border-radius: 0.9375rem;
    padding: 5px;

    &__right {
      overflow-y: auto;
      overflow-x: hidden;
      width: 100%;
      z-index: 10000000;
      padding: 5px;

      ul li {
        margin-bottom: 3px;
        span {
          font-weight: 700;
          font-family: "Bahnschrift";
          font-size: 0.8125rem;
          white-space: normal;
        }
      }
    }

    &-icon {
      z-index: 10;
      padding: 0.25rem;
      border-radius: 12px;
      min-width: 30px;
      min-height: 30px;
    }
  }
}

.org-point::after {
  content: "";
  position: absolute;
  right: 21px;
  bottom: -8px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #36383c;
}

.wrap {
  display: flex;
  flex-direction: column;

  > div {
    display: flex;
  }
}

.checkboxes {
  gap: 1rem;
  padding-top: 1rem;
  padding-bottom: 0;
}

.company-list {
  display: grid;
  width: 100%;
  grid-template-columns: 40% 40% 20%;
  padding: 1.5rem;
  color: var(--color-dark-blue);
  font-size: 16px;

  > div {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    p {
      font-weight: 700;
      font-size: 18px;
    }

    > div {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  }

  &__counts {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__flex {
    display: flex;
    align-items: baseline;
    gap: 6px;
  }
  @media (min-width: 768px) {
    padding: 1.875rem 4rem 4rem;
  }
}

.org-point__wrapper {
  cursor: pointer;
  z-index: 1000;
  pointer-events: auto;
  position: absolute;
  top: -350%;
  left: -525%;

  &:hover {
    z-index: 2000 !important;
  }
}

.source-list-card {
  position: absolute;
  width: 100%;
  height: fit-content;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 17px 14px 0px #2934951a;
}

.source-card-item {
  background-color: #fff;
  width: 100%;
  height: 100%;
  font-size: 18px;
  padding: 15px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.1s ease-in-out;
  cursor: pointer;
  gap: 12px;

  &:hover {
    background-color: var(--color-blue);
    color: white;
  }

  &__active {
    border-left: 4px solid #ff3d3d;
  }
}

.triangle-up {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #ff3d3d;
}

.card__text {
  font-size: 16px;
  line-height: 15.03px;
  color: #383874;
}

:global(.leaflet-control-zoom) {
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  box-shadow: none;
  border: none !important;
}
:global(.leaflet-control-zoom-in),
:global(.leaflet-control-zoom-out) {
  width: 3rem;
  height: 3rem;
  border-radius: 8px !important;
  background: #fff;
  color: #383874 !important;
  font-weight: 200 !important;
  font-size: 30px !important;
}
