*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

//html {
//  font-size: 50%;
//  @media screen and (min-width: 62.5em) {
//    font-size: 62.5%; // 1rem = 10px
//  }
//
//  @media screen and (min-width: 87.5em) {
//    font-size: 75%;
//  }
//
//  @media screen and (min-width: 98.75em) {
//    font-size: 100%;
//  }
//}

body {
  font-family: var(--font-primary);
  background-color: var(--color-grey-1);
}

html,
body {
  height: 100%;
}

/*
  Typographic tweaks!
  4. Add accessible line-height
  5. Improve text rendering
*/
body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}

/*
  6. Improve media defaults
*/
img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

/*
  7. Remove built-in form typography styles
*/
input,
button,
textarea,
select {
  font: inherit;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

#root,
#__next {
  isolation: isolate;
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background-color: #fff;
}

::-webkit-scrollbar-thumb {
  background-color: var(--color-blue);
  border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--color-blue);
}

.df {
  display: flex;
}

.jc-c {
  justify-content: center;
}

.jc-fe {
  justify-content: flex-end;
}

.jc-fs {
  justify-content: flex-start;
}

.jc-sb {
  justify-content: space-between;
}

.ai-c {
  align-items: center;
}

.leaflet-container {
  background-color: transparent;
  border-radius: 10px;
  z-index: 2;
}

.map-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.leaflet-container {
  width: 100%;
  height: 100%;
  border: none;
}

.region-label {
  background: none;
  box-shadow: none;
  border: none;
  font-size: 14.96px;
  font-weight: 600;
  line-height: 17.95px;
  color: #383874;
}

.map {
  width: 100%;
  height: 100%;
  position: relative;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader {
  animation: spin 1s linear infinite;
  display: inline-block;
}
