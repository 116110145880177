@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.input {
  position: relative;
  display: flex;
  flex-direction: column;
  background: #fff;

  &__label {
    font-size: 1.125rem;
    color: var(--color-dark-blue);
    line-height: 1.75rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
  }

  &__field {
    color: var(--color-dark-blue);
    outline: none;
    border: none;
    height: 100%;
    width: 100%;
    font-weight: 400;
    font-size: 20px;
    line-height: 1.75rem;
    padding-left: 1.25rem;
    background-color: transparent;

    &--invalid {
      border-color: var(--color-red-1);
    }

    &::placeholder {
      color: var(--color-dark-blue);
      font-family: inherit;
      font-size: 20px;
    }

    &:disabled {
      cursor: not-allowed;
      color: var(--color-grey-12);
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-background-clip: text;
  }

  &__tooltip {
    visibility: hidden;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 100%);
    padding: 5px 10px;
    margin-bottom: 10px;
    background-color: var(--color-white);
    color: var(--color-red-1);
    border-radius: 4px;
    opacity: 0;
    transition: opacity 0.3s ease;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
    z-index: 1;

    font-weight: 500;
    font-size: 1rem;
    line-height: 2.5rem;
    text-align: center;
    letter-spacing: -0.01em;

    &::before {
      content: '';
      position: absolute;
      top: -10px;
      left: 50%;
      transform: translateX(-50%);
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent rgba(31, 38, 47, 0.6) transparent;
    }

    &--small {
      position: absolute;
      top: 50%;
      bottom: auto;
      left: auto;
      right: 1.875rem;
      transform: translateY(-50%);
      padding: 0;
      margin-bottom: 0;
      color: var(--color-red-1);

      &::before {
        content: attr(aria-label);
        position: absolute;
        display: none;
        top: 50%;
        right: calc(100% + 0.5rem);
        transform: translateY(-50%);
        transition: opacity 0.3s ease;
        box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
        background: var(--color-white);
        padding: 0.5rem 1rem;
        border-radius: 0.25rem;
        min-width: 300px;
        font-size: 0.875rem;
        line-height: 1rem;
        animation: fade 250ms linear;
      }
      &:hover {
        &::before {
          display: block;
        }
      }
    }
  }

  &:hover .input__tooltip {
    visibility: visible;
    opacity: 1;
  }

  &__wrapper {
    position: relative;
    width: 100%;
    height: 5.0625rem;
    display: flex;
    flex-direction: row;
    align-items: baseline;
  }

  &__icon {
    position: absolute;
    top: 50%;
    right: 1.25rem;
    transform: translateY(-50%);
  }

  &--small {
    & .input__wrapper {
      height: auto;
      align-items: center;
    }
    & .input__label {
      line-height: 1.25rem;
      font-weight: 400;
      margin-bottom: 1rem;
    }

    & .input__field {
      border: 1px solid #d9d9d9;
      height: auto;
      font-size: 1.125rem;
      line-height: 1.25rem;
      padding: 1.25rem 1.875rem;
      background-color: var(--color-white);
      border-radius: 0.5rem;
      &::placeholder {
        font-size: 1.125rem;
        line-height: 1.25rem;
      }

      &:disabled {
        cursor: not-allowed;
        color: var(--color-grey-12);
      }
      &--invalid {
        border-color: var(--color-red-1);
      }
    }
  }
}

.helper {
  padding-left: 2rem;
}

// :D :D :D
.helper-password {
  padding-right: 2rem;
}
