.messages {
  width: 100%;
  background: var(--color-white);
  border-radius: 0.5rem;
  box-shadow:
    0 31px 25px 0 #2934951f,
    0 17px 14px 0 #2934951a,
    0 9px 7px 0 #29349516,
    0 4px 3px 0 #2934950f;
  padding: 1.875rem 3.125rem;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.625rem;
    gap: 1rem;
    &__title {
      font-size: 1.75rem;
      font-weight: 400;
      line-height: 2rem;
      text-align: left;
      color: var(--color-dark-blue);
    }
    &__search {
      position: relative;
      border-radius: 2.25rem;
      flex: 1;
      max-width: 37rem;
      & input {
        width: 100%;
        border: 1px solid rgba(69, 91, 220, 0.5);
        border-radius: 2.25rem;
        font-size: 1rem;
        font-weight: 350;
        line-height: 1.5625em;
        letter-spacing: -0.01em;
        text-align: left;
        padding: 0.9375rem 3rem 0.9375rem 1.875rem;
        &::placeholder {
          color: var(--color-dark-blue);
          opacity: 0.5;
        }
        &:focus {
          outline: 0;
          border: 1px solid var(--color-blue);
        }
      }
      & svg {
        position: absolute;
        top: 50%;
        right: 1.875rem;
        transform: translateY(-50%);
        color: #212838;
      }
    }
  }
  &__table {
    width: 100%;
    margin-bottom: 1.25rem;
    border-collapse: collapse;
    & td {
      font-size: 1.25rem;
      font-weight: 400;
      line-height: 1.5rem;
      color: var(--color-dark-blue);
      border-bottom: 1px solid var(--color-grey-4);
      padding: 1.875rem 0;
      & > span {
        opacity: 0.5;
      }
    }
    &__notFound {
      text-align: center;
    }
  }
  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    & > div {
      display: flex;
      gap: 1.875rem;
    }
  }
  &__transparentBtn {
    background: transparent;
    padding: 0;
    border: 0;
    color: var(--color-dark-blue);
    cursor: pointer ;
  }
  &__archiveBtn {
    background: #FF3D3D;
  }
  &__modal {
    &__header {
      display: flex;
      flex-direction: column;
    }
    &__date {
      font-size: 1.25rem;
      font-weight: 400;
      line-height: 1.5rem;
      letter-spacing: -0.01em;
      text-align: left;
      color: var(--color-dark-blue);
      opacity: 0.5;
      margin-bottom: 0.375rem;
    }
    &__title {
      font-size: 1.75rem;
      font-weight: 700;
      line-height: 2rem;
      text-align: left;
      color: var(--color-dark-blue);
    }
    &__content {
      & > div {
        &:nth-child(2n) {
          font-size: 1rem;
          font-weight: 400;
          line-height: 1.25rem;
          text-align: left;
          color: var(--color-dark-blue);
          margin-bottom: 0.3125rem;
        }
        &:nth-child(2n + 1) {
          font-size: 1.25rem;
          font-weight: 400;
          line-height: 1.5rem;
          text-align: left;
          color: #6496FB;
          margin-bottom: 1rem;
        }
        &:first-child {
          font-size: 1.75rem;
          font-weight: 700;
          line-height: 2rem;
          text-align: left;
          color: var(--color-dark-blue);
        }
        &:last-child {
          font-size: 1rem;
          font-weight: 400;
          line-height: 1.25rem;
          color: var(--color-dark-blue);
          opacity: 0.5;
          width: 100%;
          text-align: right;
          margin-bottom: 2.5rem;
        }
      }
    }
  }
}
