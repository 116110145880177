.home {
  display: flex;
  flex-direction: column;
  gap: 5rem;

  &__background {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 0;
    background-image: url('../../assets/images/typa-windows.png');
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    padding: 0.75rem 1.5rem;
  }

  &__body {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    align-items: center;
    gap: 2rem;
    text-align: -webkit-center;

    > h1 {
      color: #fff;
      text-transform: uppercase;
      z-index: 1;
    }
  }

  &__profile {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 80px;
    background: #ffffff1f;
    padding: 0.25rem 0.75rem 0.25rem 0.5rem;

    & > span {
      background: var(--color-mid-blue);
      color: var(--color-white);
      width: 2.75rem;
      height: 2.75rem;
      min-width: 2.75rem;
      min-height: 2.75rem;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.25rem;
      font-weight: 700;
      line-height: 1em;
      letter-spacing: -0.01em;
      border-radius: 50%;
      text-transform: uppercase;
    }
    &::after {
      content: '';
      display: block;
      margin-left: 0.875rem;
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid var(--color-white);
    }
    &__header {
      display: flex;
      flex-direction: column;
      padding: 1.25rem 1.375rem;
      & > span:nth-child(1) {
        font-size: 1.125rem;
        font-weight: 700;
        line-height: 1.375rem;
        color: var(--color-dark-blue);
        text-align: left;
      }
      & > span:nth-child(2) {
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1rem;
        text-align: left;
        color: var(--color-mid-blue);
      }
    }
    &__menu {
      list-style: none;
      padding: 0;
      &__item {
        display: flex;
        align-items: center;
        font-size: 1.125rem;
        font-weight: 400;
        line-height: 1.375rem;
        text-align: left;
        padding: 0.9375rem 1em;
        gap: 1em;
        background: transparent;
        white-space: nowrap;
        color: var(--color-dark-blue);
        & svg {
          min-width: 1.125rem;
        }
        &:hover {
          cursor: pointer;
          background: var(--color-blue);
          color: var(--color-white);
          box-shadow:
            0 31px 25px 0 #2934951f,
            0 17px 14px 0 #2934951a,
            0 9px 7px 0 #29349516,
            0 4px 3px 0 #2934950f;
        }
      }
    }
  }
  &__dropdown {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 300px;
    &__wrap {
      position: relative;
      display: flex;
      justify-content: space-between;
      gap: 1rem;
      align-items: center;
      border-radius: 8px;
      background: rgba(238, 240, 250, 0.08);
      box-shadow:
        0 31px 25px 0 rgba(0, 0, 0, 0.12),
        0 17px 14px 0 rgba(0, 0, 0, 0.1),
        0 9px 7px 0 rgba(0, 0, 0, 0.09),
        0 4px 3px 0 rgba(0, 0, 0, 0.06);
      backdrop-filter: blur(21.5px);
      padding: 1rem;
      cursor: pointer;
      & > p {
        color: #fff;
        font-size: 20px;
        font-weight: 600;
        line-height: normal;
      }
    }

    &__current {
      font-size: 20px;
      font-weight: 600;
      color: white;
    }

    &__options {
      background: rgba(238, 240, 250, 0.06);
      backdrop-filter: blur(21.5px);
      border-radius: 8px;
      margin-top: 0.5rem;
      z-index: 1;

      p {
        color: #fff;
        font-size: 16px;
        line-height: normal;
        text-align: left;
      }
    }
    &__optionWrap {
      display: flex;
      justify-content: space-between;
      padding: 1rem 2rem;
      border-radius: 8px;
      cursor: pointer;

      &:hover {
        background: rgba(255, 255, 255, 0.1);
      }

      &--disabled {
        cursor: not-allowed;
      }

      &--2 {
        @extend .home__dropdown__optionWrap;
        padding: 1rem 4rem;
      }
    }
  }
  &__logo {
    position: absolute;
    bottom: 50px;
    left: 50px;
    width: 118px;
    height: 95px;

    & img {
      width: 100%;
      height: 100%;
    }
  }
}
