.button {
  border: none;
  cursor: pointer;
  border-radius: 5px;
  width: 100%;
  padding: 1.44rem 0;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.4rem;
  text-align: center;
  letter-spacing: -0.005em;
  text-transform: uppercase;
  &--primary {
    color: var(--color-white);
    background-color: var(--color-dark-blue-1);
    &:hover {
      background-color: var(--color-dark-blue-2);
    }

    &:active {
      background-color: var(--color-dark-blue-3);
    }

    &:disabled {
      background-color: #cccccc;
      color: #666666;
      border: 1px solid #999999;
      cursor: not-allowed;
    }
  }

  &--secondary {
    color: var(--color-dark-blue-1);
    background: transparent;
    border: 1px solid var(--color-dark-blue-1);
    &:hover {
      color: var(--color-dark-blue-2);
    }

    &:active {
      color: var(--color-dark-blue-3);
    }

    &:disabled {
      color: #666666;
      border: 1px solid #999999;
      cursor: not-allowed;
    }
  }

  &--auth {
    background-color: var(--color-blue);
    border-radius: 0px 0px 20px 20px;
    color: var(--color-white);
  }

  &--delete {
    background-color: var(--color-red-2);
    color: var(--color-white);
    border: 1px solid var(--color-red-2);
    &:hover {
      background-color: transparent;
      color: var(--color-red-2);
    }
  }

  &--small-primary {
    height: 2.625rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.875rem;
    padding: 0.5rem 1rem;
    line-height: 2.625rem;
    white-space: nowrap;

    color: var(--color-white);
    background-color: var(--color-dark-blue-1);
    &:hover {
      background-color: var(--color-dark-blue-2);
    }

    &:active {
      background-color: var(--color-dark-blue-3);
    }

    &:disabled {
      background-color: #cccccc;
      color: #666666;
      border: 1px solid #999999;
      cursor: not-allowed;
    }
  }

  &--small-secondary {
    height: 2.625rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.875rem;
    padding: 0.5rem 1rem;
    line-height: 2.625rem;
    white-space: nowrap;

    color: var(--color-dark-blue-1);
    background: transparent;
    border: 1px solid var(--color-dark-blue-1);
    &:hover {
      color: var(--color-dark-blue-2);
    }

    &:active {
      color: var(--color-dark-blue-3);
    }

    &:disabled {
      color: #666666;
      border: 1px solid #999999;
      cursor: not-allowed;
    }
  }

  &--small-delete {
    height: 2.625rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.875rem;
    padding: 0.5rem 1rem;
    line-height: 2.625rem;
    background: #ff6969;
    color: #ffffff;
    white-space: nowrap;
  }

  &--rounded {
    display: flex;
    align-items: center;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5em;
    text-align: left;
    border-radius: 3em;
    width: auto;
    padding: 0.6875rem 1.875rem;
    letter-spacing: unset;
    background: var(--color-blue);
    color: var(--color-white);
    box-shadow:
      0 31px 25px 0 #2934951f,
      0 17px 14px 0 #2934951a,
      0 9px 7px 0 #29349516,
      0 4px 3px 0 #2934950f;

    & > svg {
      margin-right: 0.625em;
    }
  }

  &--transparent {
    @extend .button--rounded;
    background: transparent;
    color: var(--color-dark-blue);
    box-shadow: none;
  }
}
