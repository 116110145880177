.select {
  position: relative;

  img {
    cursor: pointer;
  }

  &__label {
    color: #aaa;
    font-size: 18px;
    cursor: pointer;
    padding: 10px 20px;
    //margin-left: 30px;
    //margin-bottom: 15px;

    span {
      font-size: 18px;
      font-weight: 300;
      line-height: 21.6px;
    }
  }

  &__grid {
    display: grid;
    gap: 0.5rem;
    column-gap: 1rem;
    width: min-content;
  }

  &__wrapper {
    display: flex;
    gap: 0.5rem;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    padding: 0.5rem 1rem;
    border-radius: 56px;
    height: fit-content;
    position: relative;
    cursor: pointer;
    user-select: none;
  }

  &__selected {
    font-size: 1rem;
    font-weight: 500;
    line-height: normal;
    padding: 0.5em 1.5em;
    color: var(--color-dark-blue);
    display: flex;
    gap: 6px;
    border-radius: 47px;
    background: #fff;
    align-items: center;
    min-width: max-content;
    box-shadow:
      0 31px 25px 0 rgba(41, 52, 149, 0.12),
      0 17px 14px 0 rgba(41, 52, 149, 0.1),
      0 9px 7px 0 rgba(41, 52, 149, 0.09),
      0 4px 3px 0 rgba(41, 52, 149, 0.06);
  }

  &__options {
    max-height: 420px;
    overflow-y: auto;
    z-index: 100;
    list-style: none;
    position: absolute;
    width: 400px;
    color: var(--color-dark-blue);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background: #fff;
    margin-top: 0.5rem;
    padding: 1rem;

    > li {
      padding: 1rem 0 1rem 1rem;
    }
  }
}

.option--true {
  background-color: var(--color-blue);
  color: #fff;
}

.option--disabled {
  cursor: not-allowed;
  opacity: 10;
  color: gray;
}

.active-label {
  border-bottom: 1px solid #aaaaaa;
}

.placeholder {
  font-size: 1rem;
  font-weight: 500;
  line-height: normal;
  color: #aaaaaa;
  padding: 0.5em;
}
