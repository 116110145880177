.login {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-image: url('../../../assets/images/login-bkg.png');

  &__wrapper {
    border-radius: 20px;
  }

  &__logo {
    display: flex;
    justify-content: center;
  }

  &__inside {
    width: 430px;
    margin: 0 auto;
    padding: 4rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;

    > input {
      border-radius: 20px;
      border: 1px solid #455bdc;
      background: rgba(31, 38, 47, 0.6);
      box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.25);
      backdrop-filter: blur(8.899999618530273px);
    }
  }

  &__title {
    color: var(--color-dark-blue);
    font-size: 30px;
    font-style: normal;
    font-weight: 350;
    line-height: normal;
    text-transform: uppercase;
    text-align: center;
  }

  &__link {
    text-align: center;
    font-weight: 350;
    font-size: 16px;
    text-decoration: none;
    color: #fff;
    cursor: pointer;
    display: block;
    padding-top: 2rem;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
  }
}

.u-mb-sm {
  margin-bottom: 1rem;
}

.u-mb-md {
  margin-bottom: 1.69rem;
}

.u-mb-lg {
  margin-bottom: 2rem;
}

.u-mt-sm {
  margin-top: 1rem;
}

.u-mb-xlg {
  margin-bottom: 2.8rem;
}

.button {
  display: flex;
  justify-content: center;
  font-size: 24px;
  font-weight: 700;
}

.forgot__text {
  font-size: 16px;
  font-style: normal;
  font-weight: 350;
  text-align: center;
  cursor: pointer;
  color: #fff;
  margin: 2.5rem;
}

.forgot__text--uppercase {
  font-size: 16px;
  font-style: normal;
  font-weight: 350;
  text-align: center;
  cursor: pointer;
  color: #fff;
  text-transform: uppercase;
}

.email {
  border: 1px solid #455bdc;
  background: rgba(31, 38, 47, 0.6);
}

.text-wrap {
  padding: 3rem;
  border: 1px solid #455bdc;
  border-radius: 20px 20px 0px 0px;
  background: rgba(31, 38, 47, 0.6);
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.25);

  > p {
    color: #fff;
    font-size: 20px;
    font-style: normal;
    font-weight: 350;
    line-height: normal;
  }
}
