@keyframes fade {
  0% {
    opacity: 0;
    display: none;
  }
  100% {
    opacity: 1;
    display: block;
  }
}

.popover {
  &__wrapper {
    position: relative;
    &--relativeToParent {
      position: unset;
    }
  }
  &__content {
    display: none;
    position: absolute;
    top: calc(100% + 5px);
    left: 0;
    z-index: 10;
    animation: fade 250ms linear;
    background: #ffffff;
    border-radius: 0.5rem;
    padding: 0;
    box-shadow: 0 0 6px 0 #0000001a;
    overflow: hidden;

    &--isOpen {
      display: block;
    }

    &--top {
      top: auto;
      bottom: calc(100% + 5px);
      left: 0;
    }

    &--right {
      top: 0;
      left: calc(100% + 5px);
    }

    &--left {
      top: 0;
      left: auto;
      right: calc(100% + 5px);
    }

    &--left-bottom {
      top: auto;
      bottom: 0;
      left: auto;
      right: calc(100% + 5px);
    }

    &--right-bottom {
      top: auto;
      bottom: 0;
      right: auto;
      left: calc(100% + 5px);
    }

    &--bottom-right {
      right: 0;
      left: auto;
    }
  }
}
