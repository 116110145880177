.modal {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background-color: #00000066;
  overflow: hidden;
  backdrop-filter: blur(10px);
}

.modal-content {
  max-height: 80vh;
  max-width: 80vw;
  overflow: auto;
  padding: 30px;
  position: relative;
  min-width: 300px;
  min-height: 200px;
  width: fit-content;
  height: fit-content;
  color: var(--color-dark-blue);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background: #fff;
  margin: 5rem auto;
}

.modal-close {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  rotate: 45deg;
  font-size: 30px;
  color: var(--color-dark-blue);
  font-weight: 300;
  line-height: 15px;
}

.modal-header {
  font-size: 28px;
  font-weight: 600;
  line-height: 33.61px;
  margin-bottom: 50px;
}
