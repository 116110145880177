.table__name {
  line-height: 33.61px;
  text-align: left;
  color: var(--color-dark-blue);
  padding: 23px;
  padding-left: 38px;
  font-size: 28px;
  font-weight: 600;
}

.wrapper-top {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.wrapper {
  padding-bottom: 4rem;
  display: flex;
  flex-direction: column;

  > p {
    color: var(--color-dark-blue);
    font-size: 20px;
    padding: 2rem;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;

    > div {
      display: flex;
      gap: 1rem;
    }

    > p {
      color: var(--color-dark-blue);
      font-size: 20px;
    }
  }

  &__dates {
    display: flex;
    justify-content: space-between;
    align-self: self-end;
    padding: 2rem;
  }
}

.sourceName {
  font-size: 20px !important;
  font-weight: 700 !important;
  line-height: 24px !important;
}

.sourceKey {
  font-size: 20px !important;
  font-weight: 700 !important;
  line-height: 24px !important;
}

.table {
  color: var(--color-dark-blue);
  text-align: center;
  border-collapse: collapse;
  align-self: center;
  width: 100%;

  th {
    height: 2.5rem;
    min-width: 1px;
    padding: 0 1rem;
    font-weight: 400;
    text-align: center;
    font-size: 14px;
    vertical-align: top;
    color: #38387480;
  }

  td {
    height: 4rem;
    min-width: 1px;
    padding: 0 1rem;
    font-weight: 400;
    font-size: 18px;
    font-family: Bahnschrift;
  }

  tbody {
    overflow-y: scroll;

    tr {
      &:not(:last-child) {
        border-bottom: 1px solid #cdcde1;

        td:first-child {
          font-size: 18px;
        }
      }
    }

    td {
      vertical-align: middle;
      font-size: 18px;
    }
  }
}

.headers {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  > span {
    font-size: 10px;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  > p {
    font-size: 14px;
  }
}
