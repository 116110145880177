.wrapper {
  display: flex;
  gap: 1rem;
  font-size: 16px;
  align-self: self-end;
  align-items: center;

  &__period {
    list-style: none;
    display: flex;
    font-size: 1rem;
    gap: 0.875em;
    justify-content: center;
    background-color: #ffffff80;
    padding: 0.5em 1.875em;
    border-radius: 3.5em;
    border: 1px solid rgba(69, 91, 220, 0.5);
    height: 3.5em;

    & > li {
      border-radius: 46px;
      font-size: 1em;
      line-height: normal;
      padding: 0.5em 1.5em;
      cursor: pointer;
      color: var(--color-dark-blue);
      min-width: fit-content;

      &:hover {
        background: #c2d4f8;
      }
    }

    .checked {
      background: var(--color-blue);
      color: #fff;
    }

    @media (max-width: 767px) {
      font-size: 0.75rem;
      padding: 0.5em;
      & > li {
        padding: 0.5em 1em;
      }
    }
  }

  &__options {
    list-style: none;
    display: flex;
    color: var(--color-dark-blue);
    align-items: center;
    border-radius: 47px;
    border: 1px solid rgba(69, 91, 220, 0.5);
    min-width: 245px;
    background-color: #ffffff80;
    height: 56px;

    > div {
      padding: 1rem 2rem;
      gap: 2rem;
      display: flex;
    }
  }

  &__calendar-box {
    border: 1px solid rgba(69, 91, 220, 0.5);
    border-radius: 15px;
    padding: 1.2rem;
    @media (max-width: 767px) {
      padding: 0;
      width: 2.5rem;
      height: 2.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__calendar {
    position: relative;

    &__dates {
      position: absolute;
      right: -15%;
    }
  }
}

.option {
  padding: 8px 25px;
}

.option--true {
  border-radius: 6px;
  background: var(--color-blue);
  color: #fff !important;
  box-shadow: 0px 31px 25px 0px #2934951f;
}

.calendar-wrapper {
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 99;
  background-color: #fff;
  border-radius: 14px;
  align-items: center;
  top: 50px;
  right: 50%;

  .calendar-cross {
    width: 12px;
    height: 12px;
    position: absolute;
    right: 7px;
    top: 14px;
    z-index: 100000;
    cursor: pointer;
  }

  .calendar-buttons {
    display: flex;
    align-items: center;
    gap: 5px;
    padding-bottom: 10px;

    button {
      all: unset;
      width: 150px;
      height: 30px;
      cursor: pointer;
    }

    .clear-button {
      font-weight: 400;
      font-size: 12px;
      font-family: 'Inter';
      color: #7e7e7e;
    }

    .apply-button {
      background: linear-gradient(360deg, #013773 -88.33%, #4b84f1 100%);
      text-align: center;
      line-height: 30px;
      border-radius: 10px;
      font-family: 'Nunito';
      font-style: normal;
      font-weight: 800;
      font-size: 14px;
      color: #ffffff;
      text-transform: uppercase;
    }
  }
}

.calendar {
  width: 100%;
  max-width: 511px;
  max-height: 466px;
  position: relative;
  z-index: 120;
  border-radius: 0.5rem;
  color: var(--color-dark-blue) !important;
  background: var(--color-white) !important;
  box-shadow:
      0 31px 25px 0 #2934951f,
      0 17px 14px 0 #2934951a,
      0 9px 7px 0 #29349516,
      0 4px 3px 0 #2934950f;

  button {
    color: #fff !important;
  }

  button:nth-child(7n) span {
    color: #dd8571;
  }

  span {
    text-transform: uppercase;
  }

  div {
    text-transform: capitalize;
    font-weight: 700;
    font-size: 12px;
    color: var(--color-dark-blue) !important;

    span {
      font-size: 10px;
      font-weight: 700;

      &:nth-child(7n) {
        color: #dd8571;
      }
    }
  }

  button span {
    font-size: 14px;
    font-weight: 700;
  }
}
