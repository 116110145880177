.table {
  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
}

.item {
  &__divider {
    overflow: hidden;
    font-size: 15px;
    letter-spacing: 5px;
    color: #38387433;
    margin-right: 15px;
  }

  &__name {
    white-space: nowrap;
    display: flex;
    align-items: center;
    gap: 20px;

    & span:last-child {
      font-size: 18px;
      min-width: 10px;
      min-height: 10px;
    }
  }

  &__values {
    font-size: 24px;
    display: flex;
    align-items: center;
    gap: 22px;
  }
}

.values__wrapper {
  display: flex;
  align-items: center;
  gap: 22px;
  max-width: 230px;
}

.values__wrapper::-webkit-scrollbar {
  height: 5px !important;
}

.triangle-up {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #ff3d3d;
}

.triangle-down {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #17d74d;
}

.column {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 50px;
  width: 50%;
  margin-left: auto;
  margin-bottom: 18px;

  &__name {
    font-size: 18px;
    line-height: 21.6px;
    color: #38387480;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.diagramTable {
  table-layout: fixed;
  width: 100%;
  & th {
    font-size: 1.125rem;
    line-height: 1.375rem;
    color: #38387480;
    text-align: right;
  }
  &__row {
    &--name {
      white-space: nowrap;
      display: flex;
      align-items: center;
      gap: 1.25rem;

      & span:last-child {
        font-size: 1.125rem;
        min-width: 0.625rem;
        min-height: 0.625rem;
      }
    }
    &--divider {
      overflow: hidden;
      font-size: 0.9375rem;
      letter-spacing: 0.3125rem;
      color: #38387433;
      margin: 0 1rem;
    }
    &--values {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: nowrap;
      white-space: nowrap;
      gap: 0.5rem;
    }
  }
}

.table-head-diagram {
  th {
    text-align: center;
    font-weight: 400;
  }
}
