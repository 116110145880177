.table-wrapper {
  max-width: 100%;
  overflow-x: auto;
  margin: 20px 0;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 31px 25px 0px #2934951f;
  padding: 20px 0;
}

.table-container {
  width: 100%;
  border-collapse: collapse;
  max-height: 230px;
  overflow-y: auto;

  table {
    width: 100%;
    border-spacing: 0;

    thead {
      position: sticky;
      top: 0;
      z-index: 1;

      tr {
        background-color: #ffffff;
        th {
          font-weight: bold;
          color: #383874;
          font-size: 20px;
          font-weight: 600;
          text-align: center;
        }
      }
    }

    tbody {
      padding-top: 8px;

      tr {
        transition: background-color 0.3s ease;

        &:nth-child(odd) {
          background-color: #eef0fa4d;
        }

        td {
          font-size: 20px;
          font-weight: 400;
          color: #383874;
          text-align: center;
          padding: 8px 0;
        }

        &:last-child td {
          border-bottom: none;
        }
      }
    }

    .no-data {
      text-align: center;
      padding: 20px 0;
      font-size: 16px;
      font-weight: bold;
      color: #888;
    }
  }
}

.skeleton-loader-row {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .skeleton-loader {
    width: 100%;
    height: 30px;
    background: linear-gradient(90deg, #e0e0e0 25%, #f5f5f5 50%, #e0e0e0 75%);
    background-size: 200% 100%;
    animation: shimmer 4s infinite;
    border-radius: 4px;
  }
}

@keyframes shimmer {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

.flex-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
