@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader {
  position: relative;
  &--active {
    &::before {
      content: " ";
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: var(--color-blue);
      opacity: 0.25;
      z-index: 98;
    }
  }
  &__ring {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    display: inline-block;
    width: 80px;
    height: 80px;
    z-index: 99;
    &:after {
      box-sizing: border-box;
      content: " ";
      display: block;
      width: 64px;
      height: 64px;
      margin: 8px;
      border-radius: 50%;
      border: 6.4px solid var(--color-dark-blue);
      border-color: var(--color-dark-blue) transparent var(--color-dark-blue) transparent;
      animation: lds-dual-ring 1.2s linear infinite;
    }
  }
}