.settings {
  width: 100%;
  background: var(--color-white);
  border-radius: 0.5rem;
  box-shadow:
    0 31px 25px 0 #2934951f,
    0 17px 14px 0 #2934951a,
    0 9px 7px 0 #29349516,
    0 4px 3px 0 #2934950f;
  padding: 1.875rem 3.125rem;
  &__title {
    font-size: 1.75rem;
    font-weight: 400;
    line-height: 2rem;
    text-align: left;
    margin-bottom: 1.25rem;
    color: var(--color-dark-blue);
  }
  &__list {
    display: flex;
    flex-direction: column;
    padding: 0 3.125rem;
    &__item {
      display: flex;
      align-items: center;
      gap: 1.25rem;
      padding: 2.5rem 0;
      border-bottom: 1px solid var(--color-grey-4);
      color: var(--color-dark-blue);
      & > span {
        font-size: 1.125rem;
        font-weight: 700;
        line-height: 1.25rem;
        text-align: left;
        &:first-of-type {
          font-weight: 400;
          opacity: 0.5;
          min-width: 11.25rem;
        }
      }
      & > div {
        display: flex;
        flex: 1 0;
        justify-content: flex-end;
      }
      &--editOn {
        flex-wrap: wrap;
        & > div {
          width: 100%;
          flex: auto;
          justify-content: flex-start;
          flex-direction: column;
        }
      }
    }
  }
  &__changePassword {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 6.25rem;
    margin-top: 3.125rem;
    width: 100%;
    &__column {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
    &__buttonsRow {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: flex-end;
      gap: 1.875rem;
      margin-top: 2rem;
    }
  }
}
