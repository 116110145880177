.events {
  display: flex;
  flex-direction: column;
  font-family: var(--font-secondary);
  gap: 0.625rem;
  &__title {
    font-weight: 700;
    font-size: 1.75rem;
    text-transform: uppercase;
    color: #383874;
    padding-left: 2.5rem;
  }

  &__button {
    border-radius: 3rem;
    padding: 0.75rem 1.875rem;
    text-transform: uppercase;
    color: #fff;
    background: #6496fb;
    cursor: pointer;
    border: none;

    &:active,
    &:focus {
      outline: none;
    }
  }
}

.content {
  width: 100%;
  display: flex;
  gap: 1rem;
  height: 100%;
}

.calendar {
  background: #fff;
  max-width: 66%;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  &__header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding-right: 2rem;
    padding-top: 1.5rem;

    &__left {
      display: flex;
      align-items: center;
      gap: 1rem;
      font-size: 2.25rem;
      color: #383874;
    }

    &__right {
      display: flex;
      align-items: center;
      gap: 1.875rem;
      color: #383874;
      flex-wrap: wrap;
    }

    .color-list {
      display: grid;
      align-items: center;
      row-gap: 0.625rem;
      column-gap: 1.25rem;
      flex-wrap: wrap;
      grid-template-columns: repeat(2, 1fr);

      .list-item {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        font-size: 1rem;

        span {
          width: 0.625rem;
          height: 0.625rem;
          border-radius: 50%;
        }
      }
    }
  }
}

.info-card {
  display: flex;
  background: #fff;
  padding: 1.875rem 2.5rem;
  border-radius: 0.625rem;
  flex: 1;
  color: #383874;
  flex-direction: column;
  gap: 2.5rem;
  position: relative;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  &__date {
    font-size: 1.25rem;
  }

  &__type {
    color: #ffffff;
    padding: 0.625rem 1.875rem;
    border-radius: 3rem;
    text-transform: uppercase;
    box-shadow:
      0px 31px 25px 0px #2934951f,
      0px 17px 14px 0px #2934951a,
      0px 9px 7px 0px #29349516,
      0px 4px 3px 0px #2934950f;
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
  }

  &__field-group {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }

  &__field-title {
    color: #38387499;
  }

  &__field-value {
    font-size: 1.25rem;
    line-height: 1rem;

    &--eventAmount {
      font-size: 1.75rem;
      font-weight: 600;
      line-height: 1.5rem;
    }
  }

  &__edit-button {
    position: absolute;
    right: 1.25rem;
    bottom: 1.25rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.625rem;
    background: transparent;
    border: none;
    color: #383874;

    &:active,
    &:focus {
      outline: none;
    }
  }
}

.create-modal {
  display: flex;
  flex-direction: column;
  gap: 1.875rem;

  &__header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1.25rem;
  }

  &__tab {
    padding: 0.625rem 2.5rem;
    border-radius: 0.75rem;
    color: #0e0e56;
    border: 1px solid #d9d9d9;
    cursor: pointer;

    &--selected {
      background: #6496fb;
      color: #fff;
      box-shadow:
        0px 31px 25px 0px #2934951f,
        0px 17px 14px 0px #2934951a,
        0px 9px 7px 0px #29349516,
        0px 4px 3px 0px #2934950f;
      border: none;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-height: calc(100vh - 480px);
    overflow-y: auto;
    padding-right: 0.625rem;

    &::-webkit-scrollbar {
      width: 4px;
    }
  }

  &__field-group {
    display: flex;
    flex-direction: column;
  }

  &__field-title {
    margin-bottom: 0.75rem;
    font-weight: 350;
  }

  &__field-input {
    border: 1px solid #d9d9d9;
    height: 3rem;
    border-radius: 0.5rem;
    width: 100%;
    text-indent: 1.25rem;

    &:focus {
      outline: none;
    }
  }

  &__footer {
    display: flex;
    justify-content: center;

    .events__button {
      box-shadow:
        0px 31px 25px 0px #2934951f,
        0px 17px 14px 0px #2934951a,
        0px 9px 7px 0px #29349516,
        0px 4px 3px 0px #2934950f;
    }
  }

  &__field-wrapper {
    border: 1px solid #d9d9d9;
    height: 3.125rem;
    border-radius: 0.5rem;
    width: 100%;
    // overflow: hidden;
  }
}

.react-datepicker__tab-loop {
  position: absolute;
}

.edit-modal {
  min-width: 500px;
}

.table {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  background: #fff;
  box-shadow:
    0px 9px 7px 0px #29349516,
    0px 4px 3px 0px #2934950f;
  padding: 2rem;
  border-radius: 0.625rem;
  position: relative;

  &__filters {
    display: flex;
    align-items: center;
    gap: 1.25rem;
    flex-wrap: wrap;
  }

  &__filter-group {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;

    &--right {
      margin-left: auto;
      align-self: flex-end;
    }
  }

  &__filter-title {
    font-weight: 350;
    color: #aaaaaa;
    font-size: 1.125rem;
    padding-left: 1.5rem;
  }

  .date-input-wrapper__body {
    input {
      border-radius: 47px;
      border: 1px solid #455bdc80;
      width: 208px;
    }

    .icon-wrapper {
      border: 1px solid #455bdc80;
    }
  }

  .download {
    position: absolute;
    cursor: pointer;
    right: 2.5rem;
    top: 2.5rem;
    display: flex;
    align-items: center;
    gap: 0.625rem;
    color: #383874;

    &:hover span {
      border-bottom: 1px solid #383874;
    }
  }
}

.table-container {
  width: 100%;
  overflow-x: auto;

  &::-webkit-scrollbar {
    height: 4px;
  }

  table {
    // min-width: max-content;
    width: 100%;
    table-layout: auto;
  }

  th {
    color: #38387480;
    font-size: 0.875rem;
    line-height: 1rem;
    vertical-align: top;
    padding-bottom: 0.625rem;
  }

  td {
    color: #383874;
    font-size: 1rem;
    text-align: center;
    white-space: nowrap;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0.75rem 1.25rem;
  }

  .table__input {
    padding: 0.75rem 0.625rem;
    border: 1px solid #d9d9d9;
    border-radius: 0.5rem;
    color: #383874;
    max-width: 180px;
    text-overflow: none;

    &:focus {
      outline: none;
    }
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}

.date-input-wrapper {
  &__body {
    display: flex;
    align-items: center;
    gap: 0.625rem;
    cursor: default;
  }

  input {
    color: #383874;
  }

  .icon-wrapper {
    border: 1px solid #d9d9d9;
    height: 3rem;
    width: 3rem;
    min-height: 3rem;
    min-width: 3rem;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}

// EditableCell
.inputWrapper {
  display: flex;
  align-items: center;
  width: 100%;
  border: 1px solid #d9d9d9;
  border-radius: 0.5rem;
  background: white;
  padding: 0.5rem;
  position: relative;
}

.tableInput {
  flex: 1;
  border: none;
  outline: none;
  font-size: 1rem;
  color: #383874;
  background: transparent;
  width: 100%;
  padding-right: 2rem;
}

.textarea {
  resize: none; // Disable manual resizing
  overflow: hidden;
  min-height: 1.5rem; // 1 row default height
  line-height: 1.5rem;
  transition: height 0.2s ease-in-out;
}

.expanded {
  min-height: 3rem; // Expands dynamically when editing
  padding-bottom: 2rem;
}

.actionButtons {
  position: absolute;
  bottom: 0.625rem;
  right: 0.5rem;
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.icon {
  cursor: pointer;
  font-size: 1rem;
}

.success {
  color: #56ED72;
  border: 1px solid #56ED72;
  border-radius: 7px;
}

.cancel {
  color: #AAAAAA;
  border: 1px solid #AAAAAA;
  border-radius: 7px;
}
