@font-face {
  font-family: 'Roboto Condensed';
  src: url('../fonts/RobotoCondensed.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'Roboto Condensed';
  src: url('../fonts/RobotoCondensed-Italic.ttf') format('truetype');
  font-style: italic;
}

@font-face {
  font-family: 'Bahnschrift';
  src: url('../fonts/Bahnschrift.ttf') format('truetype');
  font-style: normal;
}
