.wrapper {
  position: relative;
  padding-top: 1rem;

  @media (min-width: 768px) {
    padding: 2rem;
  }

  > h1 {
    color: var(--color-dark-blue);
    font-size: 18px;
    font-weight: 600;
    padding-left: 1rem;
  }

  > p {
    color: var(--color-dark-blue);
    font-size: 18px;
    padding-left: 1.5rem;
  }

  &__lines {
    position: absolute;
    border-radius: 6px;
    border: 0.64px solid rgba(69, 91, 220, 0.4);
    background: #fff;
    padding: 0.5rem 1rem;
    color: var(--color-dark-blue);
    font-weight: 600;
    right: 5%;
    top: 3%;
  }

  &__line-wrap {
    display: flex;
    gap: 2rem;
    align-items: baseline;
    line-height: normal;

    &__line {
      width: 50px;
      height: 5px;
    }

    &__dash {
      width: 50px;
      border-spacing: 15px;
    }
  }
}

.custom-legend {
  background-color: #fff;
  position: absolute;
  top: 2rem;
  right: 2rem;
  border: 0.64px solid #455bdc66;
  padding: 10px;
  border-radius: 6px;
  max-height: 110px;
  height: fit-content;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #38387480;
  }
}

.closeIcon {
  cursor: pointer;
  position: sticky;
  top: 5px;
  left: 100%;
}

.dashed-line {
  width: 50px;
  height: 5px;
  background-color: transparent;
  border: 0;
  margin-right: 20px;
}

.legend-wrapper {
  display: flex;
  align-items: center;
}
