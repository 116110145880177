ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

.dropdown__wrapper {
  position: relative;
}

.dropdown__selection {
  font-weight: 400;
  font-size: 16px;
  line-height: 19.2px;
  border: 1px solid #455bdc80;
  padding: 18px 25px;
  cursor: pointer;
  border-radius: 47px;
}

.dropdown__list-wrapper {
  position: absolute;
  z-index: 100;
  top: calc(100% + 10px);
  right: 0;
  background-color: #fff;
  box-shadow: 0px 9px 7px 0px #29349516;
  border-radius: 8px;
  overflow-y: auto;
  max-height: 200px;
}

.dropdown__list-item {
  font-weight: 400;
  font-size: 16px;
  line-height: 19.2px;
  padding: 12px 50px;
  cursor: pointer;
  user-select: none;
}

.dropdown__list-item:not(.disabled):hover {
  background-color: var(--color-blue);
  color: #fff;
}

.disabled {
  background-color: #fff;
  color: #38387480;
  cursor: default;
}
