.container {
  position: relative;
  display: flex;
  cursor: pointer;
}

.slider {
  display: block;
  position: relative;
  width: 56px;
  height: 32px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 24px;
  transition: 0.4s;
  background: #eef0fa;
  box-shadow: 0px 4px 4px 0px #00000040 inset;
  &.active {
    background: #383874e5;
    & .slider-thumb {
      left: calc(100% - 28px);
    }
  }
}

.slider-thumb {
  position: absolute;
  top: 4px;
  left: 4px;
  width: 24px;
  height: 24px;
  background-color: #ffffff;
  border-radius: 50%;
  transition: 0.4s;
  box-shadow: 0px 4px 4px 0px #00000040;
}
