.tooltip-container {
  position: relative;
  display: inline-block;
  user-select: none;

  .tooltip-content {
    margin-top: 14px;
    position: absolute;
    top: 100%;
    right: 0;
    margin-bottom: 8px;
    padding: 13px 20px;
    background-color: #36383c;
    color: white;
    border-radius: 15px;
    white-space: nowrap;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
  }
}

.tooltip-trigger {
  display: flex;
  justify-content: end;
}
