.main {
  display: flex;
}

.list {
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
  gap: 4rem;
  list-style: none;
  width: 26px;
  height: 26px;

  &__last {
    width: 106px;
    height: 106px;
    margin-left: -2.5rem;
    margin-top: -2.5rem;
  }

  &__notif {
    width: 106px;
    height: 106px;
    margin-left: -1.2rem;
    margin-top: -5.5rem;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex: 1 1 100%;
  gap: 2rem;
  max-height: calc(100vh - 8.5rem);

  &.streched {
    height: calc(100vh - 8.5rem);
  }

  &__grid {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 2rem;
    @media (min-width: 768px) {
      flex-wrap: nowrap;
    }
  }

  &__table {
    display: flex;
    flex: 1 100%;
    justify-content: center;
    max-height: 200px;
    overflow-y: auto;
    @media (min-width: 768px) {
      flex: 1 75%;
    }
  }

  &__info {
    flex: 1 100%;
    @media (min-width: 768px) {
      flex: 1 25%;
    }
  }
}

.dropdown {
  z-index: 100;
  position: absolute;
  width: 400px;
  color: var(--color-dark-blue);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background: #fff;
  margin-top: 5rem;

  &__header {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 1.4rem 1.2rem;
  }

  > ul {
    > li {
      padding: 1.5rem;
    }
  }

  &__orgs {
    overflow-y: scroll;
    height: 400px;
    > li {
      padding: 1rem 0 1rem 1rem;
    }
  }

  > input {
    border-radius: 4px;
    height: 100%;
    width: 100%;
    flex: 0 1 100%;
    padding: 0.5rem;
    margin: 0.5rem;
  }
}

.modal {
  &__checklist {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding-bottom: 2rem;
  }
}

.cross {
  position: relative;
  width: 16px;
  height: 16px;
}

.cross::before,
.cross::after {
  content: '';
  position: absolute;
  height: 2px;
  width: 100%;
  background-color: #fff;
  top: 50%;
  left: 0;
  transform-origin: center;
}

.cross::before {
  transform: rotate(45deg);
}

.cross::after {
  transform: rotate(-45deg);
}

.layers {
  display: flex;
  flex-direction: column;
  list-style: none;
  padding-bottom: 1rem;

  > li {
    display: flex;
    gap: 6px;
    padding: 1rem !important;
  }
}

.checkbox-wrap--true {
  background-color: var(--color-blue);
  color: #fff;
}

.checkbox-wrap--disabled {
  cursor: not-allowed;
  opacity: 10;
  color: gray;
}

.table-max_height {
  max-height: 100px;
}

.table {
  width: 100%;
  padding: 0 1rem;
  color: var(--color-dark-blue);
  font-size: 15px;
  line-height: 100%;
  border-collapse: collapse;
  // display: block;
  overflow-y: auto;

  thead {
    position: sticky;
    top: 0;
    background: #fff;
  }

  tbody {
    tr {
      &:nth-child(odd) {
        background: rgba(238, 240, 250, 0.3);
      }
    }
  }

  th {
    padding: 6px 3rem;
  }

  td {
    padding: 0.5rem;
    text-align: center;
  }

  p {
    text-align: center;
    cursor: pointer;
    &:hover {
      background-color: #ffffff1f;
    }
  }
}

.info-wrapper {
  display: flex;
  height: 100%;
  padding: 25px;
  width: 100%;
}

.info {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  gap: 32px;

  p {
    color: var(--color-dark-blue);
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
  }
  span {
    font-size: 70px;
    color: var(--color-blue);
    line-height: 50px;
  }
}

.info-divider {
  background-color: var(--color-grey-4);
  width: 1px;
  max-height: 100%;
  margin: 5px 20px;
}

.info__amount {
  cursor: pointer;
  line-height: 48px !important;
  border-bottom: 2px solid var(--color-blue);
  position: relative;
  img {
    position: absolute;
    right: -30px;
    bottom: 0;
  }
}

.tables {
  display: flex;
  gap: 2rem;
  width: 100%;
  justify-content: space-between;
}

.sidebar {
  display: flex;
  gap: 8px;
  justify-content: end;

  img {
    cursor: pointer;
  }
}

.flex {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
}

.high-company-modal-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.high-company-modal-wrapper__right {
  display: flex;
  gap: 18px;
}

.source-list {
  display: flex;
}
