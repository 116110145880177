.login {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-image: url('../../../assets/images/login-bkg.png');
  background-size: cover;

  &__wrapper {
    border-radius: 20px;
    width: 100%;
    padding: 0 2rem;
    @media (min-width: 640px) {
      max-width: 30rem;
      padding: 0;
    }
  }

  &__line {
    width: 100%;
    border-bottom: 1px solid var(--color-dark-blue);
  }

  &__inside {
    margin: 0 auto;
    margin: 4rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0px 58px 47px 0px #29349526;
    border-radius: 20px;

    > input {
      border-radius: 20px;
      border: 1px solid #455bdc;
      background: #fff;
      box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.25);
    }
  }

  &__title {
    color: var(--color-dark-blue);
    font-size: 32px;
    font-style: normal;
    line-height: normal;
    text-transform: uppercase;
    text-align: center;
    font-weight: 400;
    line-height: 38.41px;
  }

  &__link {
    text-align: center;
    font-weight: 3500;
    font-size: 16px;
    text-decoration: none;
    color: var(--color-dark-blue);
    cursor: pointer;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.u-mb-sm {
  margin-bottom: 1rem;
}

.u-mb-md {
  margin-bottom: 1.69rem;
}

.u-mb-lg {
  margin-bottom: 2rem;
}

.u-mt-sm {
  margin-top: 1rem;
}

.u-mb-xlg {
  margin-bottom: 2.8rem;
}

.button {
  display: flex;
  justify-content: center;
  font-size: 24px;
  font-weight: 700;
}

.forgot__text {
  margin-bottom: 4rem;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.5625rem;
  text-align: center;
}

.forgot__text--bold {
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.5625rem;
  text-align: center;
  margin-bottom: 1.875rem;
}

.forgot__text--underline {
  text-decoration: underline;
  font-size: 1.125rem;
  line-height: 1.5625rem;
  text-align: center;
  cursor: pointer;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.email {
  border-radius: 20px 20px 0px 0px;
}
