.emissionChart {
  position: relative;
  padding-bottom: 30px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 31px 25px 0 #2934951f;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (min-width: 768px) {
      position: absolute;
      left: 50%;
      top: 1.875rem;
      transform: translateX(-50%);
      justify-content: center;
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: 1fr;
    padding: 1rem;
    margin-bottom: 1rem;
    gap: 1rem;
    @media (min-width: 768px) {
      padding: 2rem;
      grid-template-columns: 50% 50%;
      gap: 0;
    }
  }

  &__card {
    display: flex;
    justify-content: start;
    flex-direction: column;
    &__title {
      font-size: 1.5rem;
      font-weight: 400;
      line-height: 1.75rem;
      text-align: left;
      color: var(--color-dark-blue);
      margin: 0 0 1rem;
    }
    @media (min-width: 768px) {
      &:last-child {
        & .emissionChart__card__title {
          text-align: right;
        }
      }
      &__title {
        margin: 0 0 3rem;
      }
    }
  }

  &__row {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    & > div {
      &:first-child {
        flex: 1 100%;
        display: flex;
        justify-content: center;
      }
      &:last-child {
        flex: 1 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        overflow: auto;
      }
    }
    @media (min-width: 1020px) {
      flex-direction: row;
      padding: 0 1rem;
      & > div {
        &:first-child {
          flex: 0 1 40%;
        }
        &:last-child {
          flex: 0 1 60%;
        }
      }
    }
  }
}

span {
  white-space: nowrap;
}
