.full-calendar {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  justify-content: space-between;
  gap: 1.5rem;
  font-family: var(--font-secondary);
  color: #383874;

  .full-calendar-item {
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 0.8rem;
    padding: 0.75rem 1rem;

    .month-name {
      font-size: 2rem;
      font-weight: 400;
      text-transform: uppercase;
      padding: 0.18rem;
    }

    .custom-calendar {
      background-color: transparent;
      color: var(--color-dark-blue);

      .custom-table {
        // border-spacing: 6px;
        table-layout: fixed;
        border-collapse: separate;
        width: 100%;
      }

      .date-day {
        border-radius: 0.3rem;
        color: var(--color-dark-blue);
        text-align: center;
        text-box: auto;
        // padding: 6px;
        alignment-baseline: text-after-edge;
        // width: 100%;
        // display: block;
        // padding: 0.3rem;
        width: 100%;
        aspect-ratio: 1 / 1;
        text-align: center;
        vertical-align: middle;
        white-space: nowrap;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0.1875rem;

        p {
          width: 100%;
          // padding: 0.3rem;
        }

        &:hover {
          border: 1px solid #6496FB;
          border-radius: 0.3rem;
          cursor: pointer;
        }
      }
      .custom-today {
        // background-color: #dbdff1 !important;
        // font-size: 1.75rem;
        border: 0.0625rem solid #6496fb;
        border-radius: 0.1875rem;
      }

      .custom-selected {
        font-size: 1.25rem;
        border: 1px solid #695DFB;
        padding: 2px;
      }

      .other-month-day {
        opacity: 0.3;
        pointer-events: none;
      }

      .event-day {
        font-weight: bold;
        position: relative;
        cursor: pointer;
        box-shadow: 0px 0.625rem 0.9375rem -0.5rem rgba(0, 0, 0, 0.75);

        .day-info {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .event-indicators {
          position: absolute;
          padding: 0.0625rem 0.1875rem;
          border-radius: 0.125rem;
          display: flex;
          gap: 0.125rem;
          width: max-content;
          right: 0;
          top: -0.1875rem;

          .event-circle {
            position: relative;
            display: block;
            width: 0.5rem;
            height: 0.5rem;
            border-radius: 50%;
          }
        }

        .custom_tooltip {
          position: absolute;
          // left: calc(100% - 10px);
          right: -1.5625rem;
          top: 100%;
          display: none;
          padding: 1.25rem;
          width: 21.875rem;
          background: rgba(54, 56, 60, 1);
          color: #fff;
          border-radius: 0.937rem;
          box-shadow: 0px 0.187rem 0.75rem -5px rgba(0, 0, 0, 0.75);
          z-index: 99;
          font-size: 0.875rem;
          font-weight: 400;

          p {
            padding: 0;
          }
        }
      }

      .single-event-day {
        color: #fff;
        box-shadow: 0px 0.3125rem 0.625rem -0.25rem rgba(0, 0, 0, 0.75);
      }

      .show-events {
        .custom_tooltip {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 0.625rem;

          &__block {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
            white-space: break-spaces;
            text-align: start;

            p {
              opacity: 0.6;
              font-weight: 350;
            }
          }
          &__blocks {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 0.625rem;
          }
          &__header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            font-size: 1rem;

            .event-name {
              display: flex;
              align-items: center;
              gap: 0.625rem;
              text-transform: uppercase;

              span {
                display: block;
                width: 0.875rem;
                height: 0.875rem;
                border-radius: 50%;
              }
            }

            .event-buttons {
              display: flex;
              align-items: center;
              gap: 0.3125rem;

              .active-btn {
                position: relative;

                &::after {
                  content: "";
                  position: absolute;
                  width: calc(100% + 0.25rem);
                  height: 0.0625rem;
                  bottom: 0.25rem;
                  background: #fff;
                  left: -0.125rem;
                  right: -0.125rem;
                }
              }
            }
          }
        }
      }

      .highlight-date {
        background-color: rgba(219, 223, 241, 1) !important;
        color: var(--color-dark-blue) !important;
        font-weight: bold;
        font-size: 1.125rem;
      }

      .evet-wrap {
        padding: 0.1875rem;
      }
    }
    .custom-weekday {
      padding: 0.3125rem;
      text-transform: uppercase;
      font-size: 0.875rem;
    }
    .hide-element {
      display: none !important;
    }
  }

  @media (max-width: 1360px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (max-width: 1140px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 720px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 500px) {
    grid-template-columns: repeat(1, 1fr);
  }


  &.is-events {
    grid-template-columns: repeat(4, 1fr);
    max-height: 40rem;
    overflow-y: auto;
    
    &::-webkit-scrollbar {
      width: 4px;
    }

    .full-calendar-item {
      background: #F4F5FA66;
    }
  }
}
.full-calendar-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .color-list {
    display: flex;
    align-items: center;
    gap: 1.25rem;
    width: max-content;
    flex-wrap: wrap;
    row-gap: 0.1875rem;

    .list-item {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      font-size: 1rem;

      span {
        width: 0.625rem;
        height: 0.625rem;
        border-radius: 50%;
      }
    }
  }
}
