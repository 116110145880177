.download-wrapper {
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  position: relative;
}

.download-dropdown {
  background-color: #ffffff;
  box-shadow: 0px 31px 25px 0px #2934951f;
  border-radius: 8px;
  max-width: 320px;
  position: absolute;
  top: 100%;
  left: 20px;
  z-index: 100;
  border: 1px solid #6496fb;
  user-select: none;

  opacity: 0;
  transform: translateY(-10px);
  transition:
    opacity 0.2s ease,
    transform 0.2s ease;
  pointer-events: none;
}

.download-dropdown.open {
  opacity: 1;
  transform: translateY(0);
  pointer-events: auto;
}

.download-txt {
  color: #383874;
  border-bottom: 1px solid transparent;

  &:hover {
    border-bottom: 1px solid #383874;
  }
}

.dropdown-header {
  font-size: 18px;
  font-weight: 600;
  padding: 16px 6px;
  border-bottom: 1px solid #6496fb;
  margin: 0 30px;
  color: #383874;
}

.download-checkbox {
  appearance: none;
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #383874;
  min-width: 20px;
  height: 20px;
  border-radius: 7px;
  position: relative;
  display: inline-block;

  &:checked {
    background-color: #6496fb;
    border-color: #6496fb;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -60%) rotate(45deg);
      width: 5px;
      height: 10px;
      border-right: 2px solid white;
      border-bottom: 2px solid white;
    }
  }
}

.download-dropdown-item {
  display: flex;
  gap: 10px;
  width: 100%;
  padding: 16px 36px;
  transition:
    background-color 0.15s ease-in-out,
    color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  font-size: 1rem;
  font-weight: 400;
  color: #383874;
  align-items: center;

  &:hover {
    background-color: #6496fb;
    color: #fff;
    box-shadow: 0px 31px 25px 0px #2934951f;

    .download-checkbox {
      border-color: #fff;
    }
  }
}

.active {
  box-shadow: 0px 31px 25px 0px #2934951f;
  background-color: #6496fb;
  color: #fff;

  .download-checkbox {
    border-color: #fff;
  }
}

.dropdown-footer{
  padding: 25px;
  display: flex;
  justify-content: center;
}

.download-button-content {
  display: flex;
  gap: 10px;
  align-items: center;
}

.download-trigger {
  display: flex;
  gap: 10px;
  align-items: center;
}