.rangePicker {
  position: relative;
  display: flex;
  gap: 1.25rem;
  &__inputWrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #455bdc80;
    border-radius: 3rem;
    padding: 1.125rem 1.5rem;
    max-width: 13rem;
    gap: 0.5rem;
    &:before {
      content: '-';
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  &__calendarWrapper {
    position: absolute;
    top: 100%;
    left: 0;
    border-radius: 0.5rem;
    background: var(--color-white) !important;
    box-shadow:
      0 31px 25px 0 #2934951f,
      0 17px 14px 0 #2934951a,
      0 9px 7px 0 #29349516,
      0 4px 3px 0 #2934950f;
  }
  &__input {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.25em;
    letter-spacing: -0.01em;
    text-align: center;
    border: 0;
    background: transparent;
    width: 100%;
    padding: 0;
    &:focus {
      outline: 0;
      border-bottom: 1px solid var(--color-blue);
      margin-bottom: -1px;
    }
  }
  &__pickerBtn {
    border: 1px solid #455bdc80;
    border-radius: 0.9375rem;
    background: transparent;
    padding: 1.125rem;
    color: var(--color-dark-blue);
    cursor: pointer;
    &:hover {
      background: var(--color-blue);
      color: var(--color-white);
    }
  }
}
