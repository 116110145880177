@keyframes slide {
  from {
    right: -50vh;
  }
  to {
    right: 0;
  }
}

.layout {
  display: flex;
  background: #eef0fa;
  min-height: 100vh;
  flex-direction: column;
  overflow-x: hidden;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    padding: 0 1rem;
    @media (min-width: 768px) {
      padding: 0 2rem;
    }
  }

  &__profile {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    & > span {
      background: var(--color-blue);
      color: var(--color-white);
      width: 2.75rem;
      height: 2.75rem;
      min-width: 2.75rem;
      min-height: 2.75rem;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.25rem;
      font-weight: 700;
      line-height: 1em;
      letter-spacing: -0.01em;
      border-radius: 50%;
      text-transform: uppercase;
    }
    &::after {
      content: "";
      display: block;
      margin-left: 0.875rem;
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid #292e45;
    }
    &__header {
      display: flex;
      flex-direction: column;
      padding: 1.25rem 1.375rem;
      & > span:nth-child(1) {
        font-size: 1.125rem;
        font-weight: 700;
        line-height: 1.375rem;
        color: var(--color-dark-blue);
        text-align: left;
      }
      & > span:nth-child(2) {
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1rem;
        text-align: left;
        color: var(--color-mid-blue);
      }
    }
    &__menu {
      list-style: none;
      padding: 0;
      &__item {
        display: flex;
        align-items: center;
        font-size: 1.125rem;
        font-weight: 400;
        line-height: 1.375rem;
        text-align: left;
        padding: 0.9375rem 1em;
        gap: 1em;
        background: transparent;
        white-space: nowrap;
        color: var(--color-dark-blue);
        & svg {
          min-width: 1.125rem;
        }
        &:hover {
          cursor: pointer;
          background: var(--color-blue);
          color: var(--color-white);
          box-shadow:
            0 31px 25px 0 #2934951f,
            0 17px 14px 0 #2934951a,
            0 9px 7px 0 #29349516,
            0 4px 3px 0 #2934950f;
        }
      }
    }
  }
  &__notification {
    background: transparent;
    border: 0;
    padding: 1rem;
    margin-left: 2rem;
    position: relative;
    color: #212838;
    cursor: pointer;
    & > svg:not(:first-child) {
      position: absolute;
      top: 22%;
      right: 22%;
    }
  }

  &__outlet {
    padding: 1rem;
    height: 100%;
    flex: 1;
    @media (min-width: 768px) {
      padding: 1.875rem;
    }
  }

  &__search-wrap {
    display: flex;
    align-items: center;

    img {
      position: absolute;
      right: 37%;
    }
  }

  &__search {
    border-radius: 14px;
    background: #eef0fa;
    border: none;
    height: 38px;
    width: 500px;
    padding: 16px;
    position: relative;
  }
  &__navigation {
    display: none;
    @media (min-width: 768px) {
      display: flex;
      gap: 1.875rem;
    }
    &--mobile {
      display: block;
      background: transparent;
      border: 0;
      padding: 0;
      color: var(--color-dark-blue);
      @media (min-width: 768px) {
        display: none;
      }
    }
    &__item-wrapper {
      position: relative;
      font-family: "Bahnschrift";
      color: #383874;
      font-size: 1.125rem;

      & .navigation-dropdown {
        position: absolute;
        top: 100%;
        left: 0;
        background: #fff;
        z-index: 100000;
        display: flex;
        flex-direction: column;
        border-radius: 8px;
        box-shadow: 
            0px 9px 7px 0px #29349516,
            0px 4px 3px 0px #2934950f;
        width: 20rem;
        align-items: flex-start;
      }

      & .navigation-dropdown__item {
        padding: 1rem 3.125rem;
        color: #383874;
        font-size: 1.125rem;
        font-family: "Bahnschrift";
        width: 100%;
        cursor: pointer;

        &:first-child {
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
        }

        &:last-child {
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;
        }

        &:hover {
          background: #6496fb;
          box-shadow:
            0px 31px 25px 0px #2934951f,
            0px 17px 14px 0px #2934951a,
            0px 9px 7px 0px #29349516,
            0px 4px 3px 0px #2934950f;
          color: #fff;
        }
      }
    }
    &__item {
      padding: 1.5rem;
      position: relative;
      cursor: pointer;
      &--active {
        border-bottom: 4px solid #6496fb;
      }
    }
  }
  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background: rgba(0, 0, 0, 0.25);
    z-index: 998;
  }
  &__mobileMenu {
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 0;
    right: 0;
    height: 100vh;
    width: 50vh;
    animation: slide 200ms linear;
    background: var(--color-white);
    z-index: 999;
    box-shadow:
      0 31px 25px 0 rgba(0, 0, 0, 0.12),
      0 17px 14px 0 rgba(0, 0, 0, 0.1),
      0 9px 7px 0 rgba(0, 0, 0, 0.09),
      0 4px 3px 0 rgba(0, 0, 0, 0.06);
    & > svg {
      position: absolute;
      right: 1rem;
      top: 1rem;
      cursor: pointer;
    }
    @media (min-width: 768px) {
      display: none;
    }
  }
}

.dropdown {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  background: rgba(238, 240, 250, 0.08);
  box-shadow:
    0 31px 25px 0 rgba(0, 0, 0, 0.12),
    0 17px 14px 0 rgba(0, 0, 0, 0.1),
    0 9px 7px 0 rgba(0, 0, 0, 0.09),
    0 4px 3px 0 rgba(0, 0, 0, 0.06);
  backdrop-filter: blur(21.5px);
  padding: 1rem;
  cursor: pointer;

  > p {
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    line-height: normal;
  }

  &__options {
    box-shadow: 0 31px 25px 0 #2934951f;
    backdrop-filter: blur(21.5px);
    border-radius: 8px;
    margin-top: 0.5rem;

    p {
      white-space: nowrap;
      color: var(--color-dark-blue);
      font-size: 16px;
      line-height: normal;
      text-align: left;
    }

    &__item {
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      color: var(--color-dark-blue);
    }
  }

  &__option-wrap {
    display: flex;
    justify-content: space-between;
    padding: 1rem 2rem;
    border-radius: 8px;
    cursor: pointer;

    &--disabled {
      cursor: not-allowed;
    }

    &:hover {
      background: rgba(255, 255, 255, 0.1);
    }
  }

  &__option-wrap2 {
    display: flex;
    justify-content: space-between;
    padding: 1rem 4rem;
    border-radius: 8px;
    cursor: pointer;

    &--disabled {
      cursor: not-allowed;
    }

    &:hover {
      background: rgba(255, 255, 255, 0.1);
    }
  }
}

.cross {
  position: relative;
  width: 13px;
  height: 13px;
  rotate: 45deg;
}

.cross::before,
.cross::after {
  content: "";
  position: absolute;
  background-color: var(--color-dark-blue);
}

.cross::before {
  width: 2px;
  height: 14px;
  left: calc(50% - 1px);
  top: 0;
}

.cross::after {
  width: 14px;
  height: 2px;
  left: 0;
  top: calc(50% - 1px);
}

.triangle-down {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #bbaacc;
}

.triangle-up {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #bbaacc;
}

.notification {
  &__list {
    display: flex;
    flex-direction: column;
    min-width: 15rem;
    max-height: 80vh;
    overflow: auto;
    background: var(--color-white);
    @media (min-width: 768px) {
      min-width: 30rem;
    }
  }
  &__wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 1.875rem 1.25rem;
    width: 100%;
    & > svg {
      position: absolute;
      top: 1.875rem;
      left: 1.25rem;
    }
    & > button {
      position: absolute;
      top: 1.875rem;
      right: 1.25rem;
      background: transparent;
      border: 0;
      padding: 0;
      cursor: pointer;
      color: var(--color-dark-blue);
      &:hover {
        color: var(--color-blue);
      }
    }
    &:not(:last-child) {
      &:after {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        left: 1.875rem;
        width: calc(100% - 3.75rem);
        height: 1px;
        background: var(--color-dark-blue);
        opacity: 0.5;
      }
    }
    &--seen {
      opacity: 0.5;
    }
  }
  &__row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    column-gap: 3.125rem;
    padding: 0 2.1875rem;
    &:first-of-type {
      margin-bottom: 0.875rem;
    }
  }
  &__title {
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.5rem;
    text-align: left;
    color: var(--color-dark-blue);
  }
  &__description {
    font-size: 1rem;
    font-weight: 350;
    line-height: 1.25em;
    text-align: left;
    color: var(--color-dark-blue);
  }
  &__date {
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 0.875rem;
    letter-spacing: -0.01em;
    text-align: left;
    color: var(--color-dark-blue);
    opacity: 0.5;
    white-space: nowrap;
  }
  &__value {
    font-size: 1.875rem;
    font-weight: 400;
    line-height: 2.25rem;
    text-align: left;
    color: var(--color-red-3);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
