span {
  white-space: nowrap;
}

.diagram {
  &__name {
    width: 100%;
    font-size: 24px;
    line-height: 28.8px;
    color: var(--color-dark-blue);
    @media (min-width: 768px) {
      width: auto;
      max-width: 33%;
    }
  }
  &__header {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    position: relative;
    gap: 1rem;
    @media (min-width: 768px) {
      flex-wrap: nowrap;
      min-height: 112px;
      gap: unset;
    }
  }
  &__dropdown {
    max-width: 350px;
    & div:first-child {
      z-index: 2;
    }
    & div:last-child {
      z-index: 1;
    }
  }
  &__year {
    @media (min-width: 768px) {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  &__row {
    display: flex;
    flex-direction: column;
    width: 100%;
    & > div {
      padding: 1rem 0;
      flex: 1 100%;
      &:first-child {
        display: flex;
        justify-content: center;
      }
      &:last-child {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        overflow: auto;
      }
    }
    @media (min-width: 1020px) {
      flex-direction: row;
      & > div {
        padding: 0 2rem;
        flex: 0 1 50%;
      }
    }
  }
}

.card {
  background-color: #fff;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  box-shadow: 0 31px 25px 0 #2934951f;
  width: 100%;
  border-radius: 8px;
  @media (min-width: 768px) {
    gap: 40px;
    padding: 10px 50px 50px 50px;
    width: calc(100% - 2rem);
  }
}
