.checkbox {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.75rem;
  text-align: left;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: var(--color-dark-blue);
  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  &__checkmark {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    height: 20px;
    width: 20px;
    background-color: var(--color-white);
    border-radius: 7px;
    border: 1px solid var(--color-dark-blue);
    &:after {
      content: '';
      position: absolute;
      display: none;
      top: 3px;
      left: 6px;
      width: 5px;
      height: 10px;
      border: solid var(--color-dark-blue);
      border-width: 0 1.5px 1.5px 0;
      transform: rotate(45deg);
    }
  }
  &:hover input ~ .checkbox__checkmark {
    background-color: var(--color-grey-1);
  }
  & input:checked ~ .checkbox__checkmark {
    &:after {
      display: block;
    }
  }
  & input:disabled ~ .checkbox__checkmark {
    background-color: var(--color-grey-1);
    cursor: not-allowed;
  }
}
