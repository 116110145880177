:root {
  --color-blue: #6496fb;
  --color-mid-blue: #455bdc;
  --color-dark-blue: #383874;
  --color-white: #ffffff;
  --color-grey-1: #f7f7ff;
  --color-grey-2: #cfcfdd;
  --color-grey-3: #f0f1ff;
  --color-grey-4: #cdcde1;
  --color-grey-5: #b5b5d3;
  --color-grey-6: #c2c2e3;
  --color-grey-7: #ebebf6;
  --color-grey-8: #d8d8d8;
  --color-grey-9: #b8b8b8;
  --color-grey-10: #f5f5f5;
  --color-grey-11: #d4d4d4;
  --color-grey-12: #787676;

  --color-green-1: #56ed72;

  --color-red-1: #e71a1a;
  --color-red-2: #ff6969;
  --color-red-3: #ff3d3d;

  --color-dark-blue-1: #0e0e56;
  --color-dark-blue-2: #1f1f86;
  --color-dark-blue-3: #080835;
  --color-dark-blue-4: #2626ff;

  --color-black: #000000;

  --font-primary: 'Roboto Condensed', sans-serif;
  --font-secondary: 'Bahnschrift', sans-serif;

  --font-size-xxl: 3.125rem; // 50px
  --font-size-xl: 2rem; // 32px
  --font-size-lg: 1.375rem; // 22px
  --font-size-md: 1.125rem; // 18px
  --font-size-sm: 1rem; // 16px
  --font-size-xs: 0.875rem; // 14px
}
