

.dashboard {
  &__cards {
    display: flex;
    gap: 30px;
    padding-bottom: 30px;
    justify-content: center;
    flex-wrap: wrap;
    height: 100%;
  }
  &__grid {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    @media (min-width: 768px) {
      padding: 0 1rem 100px 1rem;
    }

    & > div {
      padding: 1rem;
      width: 100%;
      max-width: 1860px;
      @media (min-width: 768px) {
        padding: 0;
      }
    }

    &__cards {
      display: grid;
      grid-template-columns: 50% 48%;
      gap: 1rem;
    }
  }

  &__grid2 {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    @media (min-width: 768px) {
      padding: 0 3.125rem 1.5rem;
    }
  }

  &__header {
    font-size: 24px;
    font-weight: 400;
    color: #383874;
    margin-bottom: 1rem;
    @media (min-width: 768px) {
      padding: 30px 0 30px 50px;
      margin-bottom: 0;
    }
  }

  &__row {
    display: flex;
    gap: 12px;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    @media (min-width: 768px) {
      padding: 0;
    }
  }

  &__filters {
    display: flex;
    gap: 50px;
    @media (max-width: 767px) {
      flex-direction: column;
      gap: 0.25rem;
      width: 100%;
    }
  }

  &__dates {
    @media (max-width: 767px) {
      width: 100%;
      & > div {
        gap: 0.25rem;
      }
    }
  }
}
