.card {
    width: -webkit-fill-available;
    width: fill-available;
    width: -moz-available;
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 31px 25px 0px rgba(41, 52, 149, 0.12), 0px 17px 14px 0px rgba(41, 52, 149, 0.10), 0px 9px 7px 0px rgba(41, 52, 149, 0.09), 0px 4px 3px 0px rgba(41, 52, 149, 0.06);
}

.center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}