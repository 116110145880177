.heading {
  letter-spacing: -0.025em;
  color: var(--color-dark-blue-1);

  &--xs {
    font-size: var(--font-size-xs);
  }

  &--sm {
    font-size: var(--font-size-sm);
  }

  &--md {
    font-size: var(--font-size-md);
  }

  &--lg {
    font-size: var(--font-size-lg);
  }

  &--xl {
    font-size: var(--font-size-xl);
  }

  &--xxl {
    font-size: var(--font-size-xxl);
    line-height: 5.5rem;
  }

  &--italic {
    font-style: italic;
  }

  &--bold {
    font-weight: 700;
  }

  &--light {
    font-weight: 400;
  }

  &--regular {
    font-weight: 500;
  }
}
