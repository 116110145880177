.flex {
  display: flex;
  gap: 30px;
  align-items: flex-start;
}

.top-graph {
  display: flex;
  gap: 30px;
  justify-content: space-between;
}

.graphs {
  display: flex;
  gap: 30px;
  justify-content: space-between;
  margin-top: 60px;
}

.cards {
  display: flex;
  gap: 30px;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
  max-width: 100%;
  overflow-x: auto;
  margin: 0 auto;
}

.cards-list {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.cards-section {
  display: flex;
  flex-direction: column;
}

.cards-title {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 28px;
  font-weight: 600;
  color: #383874;
  margin: 25px 0;

  span {
    font-size: 18px;
    font-weight: 400;
    color: #383874;
  }
}

.card-waste {
  box-shadow: 0px 17px 14px 0px #2934951a;
  padding: 30px;
  color: #383874;
  background-color: #ffffff;
  border-radius: 8px;
  width: 444px;
  min-width: 444px;
  height: 260px;
  text-align: center;

  &__title {
    font-size: 24px;
    font-weight: 600;
    white-space: nowrap;
  }

  &__description {
    font-size: 20px;
    font-weight: 400;
    opacity: 0.5;
    margin-top: 48px;
  }

  &__value {
    font-size: 48px;
    font-weight: 700;
    margin-top: 18px;
  }
}
