.pagination {
  display: flex;
  gap: 1.25rem;
  justify-content: flex-end;
  align-items: center;
  &__btn {
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    background: transparent;
    &:not(:disabled) {
      cursor: pointer;
    }
  }
  &__previous {
    @extend .pagination__btn;
    border-right: 8px solid var(--color-dark-blue);
    border-left: 0;
    &:disabled {
      border-right-color: var(--color-grey-11);
    }
  }
  &__next {
    @extend .pagination__btn;
    border-left: 8px solid var(--color-dark-blue);
    border-right: 0;
    &:disabled {
      border-left-color: var(--color-grey-11);
    }
  }
  &__total {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.1875rem;
    letter-spacing: -0.01em;
    text-align: left;
    color: var(--color-dark-blue);
    padding: 1.125rem 1.5625rem;
    border: 1px solid #455BDC80;
    border-radius: 3rem;
    margin-left: 0.625rem;
  }
}