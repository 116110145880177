.year-selector{
    position: relative;

    .selector-info{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 150px;
        padding: 10px 20px;

        .selector-btn{
            cursor: pointer;
            transition: 0.5s ease all;
        }


    }

    .active{
        .selector-btn{
            transform: rotateZ(180deg);
            transition: 0.5s ease all;
        }
    }
    .year-list{
        position: absolute;
        top: calc(100% - 5px);
        left: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        gap: 10px;
        max-height: 200px;
        width: 100%;
        padding: 10px 0 10px 10px;
        background: #fff;
        overflow: auto;
        border-radius: 0 0 10px 10px;
        box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.75);

        .year-item{
            cursor: pointer;
            padding: 0 10px;
            border-radius: 4px;
            transition: 0.3s ease all;
            &:hover{
                background: var(--color-grey-2);
            }
        }
    }

}