.cardSliderContainer {
  position: relative;
  width: 100%;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
}

.cardSlider {
  display: flex;
  gap: 10px;
  overflow-x: hidden;
  scroll-behavior: smooth;
  flex-direction: row;
  max-width: calc(100% - 5rem);
}

.expanded {
  position: fixed;
  bottom: 0;
  left: 0.625rem;
  width: 100%;
  height: auto;
  max-height: 90vh;
  padding: 20px;
  z-index: 1001;
  overflow-y: auto;
  max-width: 100%;

  .cardSlider {
    flex-wrap: wrap;
    overflow-x: visible;
  }
}

.card {
  min-width: 20.625rem;
  max-width: 20.625rem;
  min-height: 8.8125rem;
  padding: 1.25rem;
  background: white;
  border-radius: 8px;
  transition: transform 0.3s ease-in-out;
  font-family: "Bahnschrift";
  font-size: 1.25rem;
  color: #383874;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__title {
    display: flex;
    gap: 0.625rem;
    align-items: center;
  }

  &__circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }

  &__values {
    display: flex;
    gap: 0.625rem;
    justify-content: space-between;
  }

  &__value {
    display: flex;
    flex-direction: column;
    font-weight: 600;

    &__small {
      font-size: 1rem;
      font-weight: 300;
      margin-left: 0.3125rem;
    }
    &__number {
      font-size: 1.75rem;
      line-height: 1rem;
      white-space: nowrap;
    }

    &__title {
      font-size: 1rem;
      line-height: 1rem;
    }
  }

  span {
    white-space: normal;
  }
}

.controls {
  display: flex;
  gap: 0.625rem;

  > div {
    display: flex;
    gap: 0.3125rem;
  }

  button {
    width: 2.625rem;
    height: 2.625rem;
    border-radius: 0.625rem;
    border: 1px solid #455bdc80;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:hover {
      background: #e7e9f7;
    }
  }
}

.toggleButton {
  margin-bottom: 10px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #0a0a261a;
  backdrop-filter: blur(6px);
  z-index: 1000;
}
