.chat {
  position: fixed;
  right: 30px;
  bottom: 30px;
  border-radius: 50%;
  border: 6px solid #6496fb;
  font-family: "Bahnschrift";

  .button {
    cursor: pointer;
  }

  &__container {
    position: relative;
  }

  &__body {
    position: absolute;
    bottom: 100%;
    right: 0;
    width: 500px;
    height: 800px;
    max-width: 500px;
    max-height: 70vh;
    background-color: #fff;
    border-radius: 15px;
    border: 1px solid #455bdc80;
    display: flex;
    flex-direction: column;
    box-shadow:
      0px 31px 25px 0px #2934951f,
      0px 17px 14px 0px #2934951a,
      0px 9px 7px 0px #29349516,
      0px 4px 3px 0px #2934950f;
    padding: 1.25rem;
  }

  &__messages {
    flex: 1;
    overflow-y: auto;
    padding-bottom: 1rem;

    &::-webkit-scrollbar {
      width: 4px;
    }
  }

  &__input-container {
    width: 100%;
    position: relative;
    height: 48px;
    border: 1px solid #455bdc80;
    border-radius: 0.625rem;

    & input {
      width: 100%;
      height: 100%;
      border: 0;
      border-radius: 0.625rem;
      text-indent: 0.625rem;
      color: #383874;

      &:focus {
        outline: none;
      }
    }

    & .chat__send-button {
      position: absolute;
      right: 0.75rem;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      background: transparent;
      border: 0;
    }
  }

  &__message {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 1rem;
    &__body {
      border: 0.5px solid #455bdc80;
      color: #383874;
      font-size: 1rem;
      border-radius: 2.5rem;
      padding: 0.75rem 1rem;
      max-width: 60%;
      line-height: 1;
      overflow: hidden;
      word-break: break-word;
      overflow-wrap: anywhere;
    }

    &--user {
      justify-content: flex-end;
    }
  }

  .loader {
    width: 25px;
    aspect-ratio: 1;
    border-radius: 50%;
    border: 4px solid #514b82;
    animation:
      l20-1 0.8s infinite linear alternate,
      l20-2 1.6s infinite linear;
  }
  @keyframes l20-1 {
    0% {
      clip-path: polygon(50% 50%, 0 0, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%);
    }
    12.5% {
      clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 0%, 100% 0%, 100% 0%);
    }
    25% {
      clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 100% 100%, 100% 100%);
    }
    50% {
      clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
    }
    62.5% {
      clip-path: polygon(50% 50%, 100% 0, 100% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
    }
    75% {
      clip-path: polygon(50% 50%, 100% 100%, 100% 100%, 100% 100%, 100% 100%, 50% 100%, 0% 100%);
    }
    100% {
      clip-path: polygon(50% 50%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 0% 100%);
    }
  }
  @keyframes l20-2 {
    0% {
      transform: scaleY(1) rotate(0deg);
    }
    49.99% {
      transform: scaleY(1) rotate(135deg);
    }
    50% {
      transform: scaleY(-1) rotate(0deg);
    }
    100% {
      transform: scaleY(-1) rotate(-135deg);
    }
  }
}
